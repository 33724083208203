import React from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper
} from '@material-ui/core'
import {useStyles, CardcurrentBalanceLogic} from './cardCurrentBalance.module'


export default function CardCurrentBalance() {
  const classes = useStyles();
  const { translator } = CardcurrentBalanceLogic();

  return (
        <Paper className={classes.paper}>
            <Container className={classes.balance}>
                <Typography variant="h5">
                {translator('cardCurrentBalance.titleCenter')}
                </Typography>
                <Typography variant="h2">0</Typography>
                <Typography variant="h4">{translator('cardCurrentBalance.subtitleCenter')} <span>0</span> USD</Typography>
            </Container>
        </Paper>
  );
}