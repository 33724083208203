import React from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button,
  Box,
  Container,
  TextField,
} from '@material-ui/core';
import {
  useStyles,
  CardOfferSelectionLogic,
} from './cardOfferSelection.module';
import { useOfferType } from './useQuery';

export default function CardOfferSelection() {
  const classes = useStyles();
  const { translator } = CardOfferSelectionLogic();
  const { type } = useOfferType();
  return (
    <Paper className={classes.paper}>
      <Container className={classes.container}>
        <Box className={classes.MoneyBox}>
          {type === 'SELL' ? (
            <Typography variant="h5">
              {translator('cardOfferSelection.sellTitleSell')}{' '}
            </Typography>
          ) : (
            <Typography variant="h5">
              {translator('cardOfferSelection.sellTitleBuy')}{' '}
            </Typography>
          )}
          <TextField
            variant="outlined"
            type="number"
            className={classes.inputField}
            defaultValue={0}
            fullWidth
          />
        </Box>

        <Box container className={classes.MoneyBox}>
          <Typography variant="h5">
            {translator('cardOfferSelection.higerPrice')}
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            className={classes.inputField}
            defaultValue={0}
            fullWidth
          />
        </Box>
      </Container>
      <Grid container className={classes.MoneyBox}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {translator('cardOfferSelection.maxAmount')} USD
          </Typography>
          <Typography variant="h2">0</Typography>
          <Typography variant="h5">
            {translator('cardOfferSelection.fee')} <span>0</span> USD
          </Typography>
        </Grid>
      </Grid>

      <Box textAlign="center" mt={2}>
        {type === 'SELL' ? (
          <Button className={classes.customButton}>
            {translator('cardEgrnCoin.sell')}
          </Button>
        ) : (
          <Button className={classes.customButton}>
            {translator('cardEgrnCoin.buy')}
          </Button>
        )}
        <Typography variant="h5">
          {translator('cardOfferSelection.feeAdvisor')}
        </Typography>
      </Box>
    </Paper>
  );
}
