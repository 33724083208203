import Translator from '../../../classes/Translator/Translator';

const CardcurrentBalanceLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};
export default CardcurrentBalanceLogic;
