import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const CardOfferSelectionLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};
export default CardOfferSelectionLogic;
