import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core';
import CardEgrnCoin from '../shared/cardEgrnCoin/cardEgrnCoin';
import CardUsd from '../shared/cardUsd/cardUsd';
import Orders from '../shared/orders/orders';
import Footer from '../footer/Footer';
import { useStyles, ExchangeLogic, WalletService } from './exchange.module';
import GraphicExchangePage from '../GraphicExchangePage/GraphicExchangePage';

const Exchange = (props) => {
  const classes = useStyles();
  const service = WalletService();
  const { translator, values } = ExchangeLogic(props, service.getData);
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.dashboardStyle}>
      <Container>
        {/* <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <User user={values.user} />
          </Grid>
        </Grid> */}
      </Container>

      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                Exchange
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardEgrnCoin
                value={values.egreenCoin}
                available={values.availableCoins} 
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardUsd
                  tooltip={'buy usd'}
                  title={'Current USD'}
                  value={values.usd}
                  available={values.availableUSD}
                  onClick={handleModalOpen}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Orders />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.coinSoon}
        ></Grid>
      </Container>
      <Footer />
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={openModal}>
          <div className={classes.modalContent}>
            <GraphicExchangePage />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default Exchange;
