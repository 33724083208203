import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import { useStyles } from './newOffer.module';
import CardCurrentBalance from '../shared/cardCurrentBalance/cardCurrentBalance';
import CardOfferSelection from '../shared/cardOfferSelection/cardOfferSelection';
import Footer from '../footer/Footer';
import NotFound from '../notFound/NotFound';
import { useOfferType } from '../shared/cardOfferSelection/useQuery';

export default function NewOffers() {
  const classes = useStyles();
  const { isValid } = useOfferType();

  if (!isValid) {
    return <NotFound />;
  }
  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                New Offer
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardCurrentBalance />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardOfferSelection />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
