import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    margin: '180px auto',
  },
  subtitleDashboard: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 30,
    marginBottom: 40,
  },
  textSubtitleDashboard: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
    '@media screen and (max-width:280px)': {
      fontSize: '40px',
    },
  },
}));

export default useStyles;
