import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    margin: '48px 0px',
    minWidth: 280,
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    border: '2px solid #00DD9E',
    borderRadius: 20,
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    padding: 24,
  },
  MoneyBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-evenly',
    width: 180,
    borderRadius: 20,
    border: '2px solid #00DD9E',
    margin: 12,
    padding: 12,
  },
  customButton: {
    flex: 1,
    margin: '0 0px 20px',
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '041370',
    },
  },
  inputField: {
    color: 'black',
    height: 48,
    background: '#FFF',
    borderRadius: 12,
    margin: '24px 0px',
  },
}));

export default useStyles;
